// Search result pages

.search-result {
	overflow: hidden;
	position: relative;
	transition: $transition-base;

	a {
		color: inherit;
	}

	a:hover, a:focus {
		text-decoration: none;
	}

	img {
		border-radius: .5rem;
	}

	// Padding, mobile only (.row only used on mobile)
	.row {
		padding: 1rem 0;
	}
}

.filter {
	.box__content {
		transition: $transition-base;

		@if $stylesheet == 'giftcards' {
			&:not(:first-child):hover {
				background: $navy-25;
			}
		}
	}
}

// GAYL Online only
@if $stylesheet == 'giveasyoulive' {
	// Used for store search letter selection
	.btn-alphabet {
		min-width: 2.25rem;
		min-height: 2.25rem;
		border-radius: 50%;
		margin: .25rem .25rem 0 0;
	}
	.btn-alphabet-0-9 {
		padding-left: 0;
		padding-right: 0;
	}

	// Favourites triangle on search pages
	.fav-triangle {
		top: 0;
		left: 0;
		z-index: 2;
		position: absolute;
		background: $silver;
		padding: .25rem 1.5rem 1.5rem .25rem;
		transition: $transition-base;
		clip-path: polygon(0 0, 0 100%, 100% 0%); // Top left triangle

		&:hover {
			background: shade-color($silver, 10%);
		}

		&.is-fav {
			background: $blue;

			&:hover {
				background: shade-color($blue, 15%);
			}
		}

		svg {
			transform: rotate(36deg); // Should be 45deg surely?! But just doesn't look right
		}

		// Adding padding to left of logo to mobile version
		& + a .row {
			padding-left: 1rem;
		}
	}

	.filter {
		i:hover {
			color: $grey;
		}
	}

	.filter__list {
		margin: 0;
		
		li > a, li > span {
			display: flex;
			padding: .25rem .75rem;
			font-size: .875rem;
			margin: 0 -.75rem;
			gap: 1rem;
		}

		li > a:hover {
			text-decoration: none;
			border-radius: $border-radius;
			background: $grey-10;
		}
	}

	.filter {
		section:has(:first-child) + section {
			margin-top: 1.5rem;
		}
	}
}