//
// Peel effect for voucher codes
//

.btn-peel {
	position: relative;
	padding-right: calc(var(--btn-padding-x) + 1rem);

	.voucher-hint {
		top: calc(0px - var(--btn-border-width));
		right: calc(-1px - var(--btn-border-width)); // Extra 1px stops background bleeding at edges
		color: $grey;
		position: absolute;
		transition: all .15s ease-in-out;
		height: calc(100% + (2 * var(--btn-border-width)));
		border: var(--btn-border-width) solid rgba($grey, 0.2);
		background: linear-gradient(to right, $grey-50 0%, #fff 50%) border-box; // Border-box required to it bleeding through semi-transparent border
		border-radius: 0 var(--btn-border-radius) var(--btn-border-radius) 0;
		padding: calc(var(--btn-border-width) + var(--btn-padding-y)) var(--btn-padding-x); 
		padding-left: 0;

		&:before {
			left: 0;
			top: -12%;
			z-index: 1;
			content: '';
			height: 125%;
			aspect-ratio: 1;
			position: absolute;
			border-radius: 50%;
			transform: translateX(-30%) rotate(33deg);
			background: linear-gradient(30deg, shade-color(#fff, 10%), shade-color(#fff, 20%) 49%, transparent 50%);
		}
	}

	// Hover un-peel effect
	&:hover {
		.voucher-hint {
			margin-left: -4%;
			padding-left: 4%;
		}
	}
}
