/*
   Hero Orbs (used on switch, travel and other vertical pages)
*/

// Hero Orbs - Structure

.orb {
	color: #fff;
	width: 150%;
	height: 100%;
	opacity: 0.8;
	margin: 0 -25%;
	background: $blue;
	position: absolute;
}

.orb-contents {
	color: #fff;
	position: relative;
	text-align: center;
	padding: 20px 30px 20px 30px;

	a:not(.btn), a:not(.btn):hover, a:not(.btn):focus, a:not(.btn):active{
		color: #fff;
	}
}

// Tablet and desktop styles
@include media-breakpoint-up(md) {
	.orb {
		margin: -5%;
		width: 110%;
		display: block;
		position: initial;
		padding-top: 110%;
		border-radius: 50%;
	}

	.orb-contents {
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		margin: auto;
		display: flex;
		flex-wrap: wrap;
		position: absolute;
		align-items: center;
	}	
}

.orb-sm {
	opacity: 1;
	padding: 0;
	left: -40%;
	bottom: 75%;
	width: 210px;
	height: 210px;
	display: flex;
	flex-wrap: wrap;
	background: $blue;
	position: absolute;
	align-items: center;
}

// Hero Orbs - Content

.orb-contents .pagetitle-xs, 
.orb-contents .pagetitle-sm {
	padding: 0 40px;
}

.orb-contents .heading-sm:first-child {
	margin-left: 20%;
	margin-right: 20%;
}

.orb-quote {
	margin: 0;
	padding: 40px 25px;
}

.orb-quote span {
	display: block;
	line-height: 1;
	margin-top: 10px;
	font-size: 3.25rem;
}

.orb-quote sup {
	font-size: 60%;
}