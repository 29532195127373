/*
 * Dashboard
 * This is for GAYL-specific stuff for the dashboard. See sass/section/dashboard.scss for general/common stuff.
 */

.store-lists .box__content:not(:last-child) {
    flex-grow: 0;
}

.section-salutations-inset {
    @include media-breakpoint-down(md) {
        border-radius: 0;
    }
}