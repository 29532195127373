//
// Countdown (used on BF/CM)
// See vendor/countdown.js
//

@mixin half_content_bottom() {
	bottom: 50%;
	width: 100%; 
	height: 100%;
	display: flex;
	position: absolute;
	align-items: center;
	justify-content: center;
}

@if $stylesheet == "giveasyoulive" {

	// == Config ==
	$color-top: #f7f7f7;
	$color-bottom: white;

	$interval_container_size: auto; //$minimum_interval_cont_width + 3em;

	$border_radius_size: 6px;

	$digit_height: 80px;
	$digit_width: 50px;
	$digit_size: 50px;

	$digit_height_mobile: 40px;
	$digit_width_mobile: 30px;
	$digit_size_mobile: 30px;

	// == Don't touch ==
	$minimum_interval_cont_width: 6 * $digit_width;
	$digit_line_height: $digit_height;
	// == Don't touch end ==

	.cd {
		&_cont {
			font-size: 13px;
			display: flex;
			color: $grey-90;
			justify-content: center;
		}

		&_interval {
			&_cont {
				display: flex;
				justify-content: space-around;
				width: $interval_container_size;
				&:nth-child(n+1):not(:last-child) {
					margin-right: 10%;
				}
			}
			&_basic_cont {
				flex: auto;
				flex-basis: 0;
				display: flex;
				max-width: 150px;
				flex-direction: column;
				&_description {
					padding: 6px;
					display: block;
					font-size: 16px;
					font-weight: bold;
					text-align: center;
					margin: 5px 10% 0 0;
					text-transform: uppercase;
					background-color: $color-top;
					border-radius: $border_radius_size;
					box-shadow: 6px 6px 6px rgba(0,0,0,.2);

					@include media-breakpoint-down(md) {
						padding: 4px;
						font-size: 14px;
					}
				}
			}
		}

		&_digit_cont {
			width: 50%;
			font-weight: bold;
			position: relative;
			height: $digit_height;
			font-size: $digit_size;
			perspective: $digit_height * 2;
			line-height: $digit_line_height;
			border-radius: $border_radius_size;
			box-shadow: 6px 6px 6px rgba(0,0,0,.2);

			&:nth-child(n+1):not(:last-child) {
				margin-right: 2%;
			}
			
			@include media-breakpoint-down(md) {
				height: $digit_height_mobile;
				font-size: $digit_size_mobile;
				line-height: $digit_height_mobile;
				perspective: $digit_height_mobile * 2;
			}
		}

		&_digit_last_placeholder, &_digit_new_placeholder {
			left: 0;
			width: 100%;
			height: 50%;
			overflow: hidden;
			text-align: center;
			position: absolute;
			z-index: 1 !important; // Manual addition to override a class from .hero>div
		}
		&_digit_last_placeholder {
			bottom: 0;
			background: $color-bottom;
			border-radius: 0 0 $border_radius_size $border_radius_size;
			&_inner {
				@include half_content_bottom;
			}
		}
		&_digit_new_placeholder {
			top: 0;
			background: $color-top;
			border-radius: $border_radius_size $border_radius_size 0 0;
		}

		&_digit_last_rotate, &_digit_new_rotate {
			top: 0;
			width: 100%;
			height: 50%;
			display: flex;
			overflow: hidden;
			font-weight: bold;
			position: absolute;
			justify-content: center;
			animation-duration: .4s;
			transform-origin: 100% 100%;
			animation-fill-mode: forwards;
			animation-timing-function: linear;
			border-radius: $border_radius_size $border_radius_size 0 0;
			
			&:after{
				left: 0;
				bottom: 0;
				content: "";
				z-index: -1;
				width: 100%;
				height: 100%;
				position: absolute;
				border-bottom: 1px solid rgba(0, 0, 0, 0.2);
			}
		}

		&_digit_last_rotate {
			background: $color-top;
			animation-name: countDown_flip_1;
		}

		&_digit_new_rotate {
			background: $color-bottom;
			animation-name: countDown_flip_2;
		}

		&_digit_new_rotated {
			width: 100%;
			height: 100%;
			transform: rotateX(180deg);
			&_inner {
				@include half_content_bottom;
			}
		}

		@keyframes countDown_flip_1 {
			0% {
				z-index:1;
				transform: rotateX(0deg);
			}
			100% {
				z-index:0;
				transform: rotateX(-180deg);
			}
		}

		@keyframes countDown_flip_2 {
			0% {
				z-index:0;
				transform: rotateX(0deg);
			}
			100% {
				z-index:1;
				transform: rotateX(-180deg);
			}
		}
	}
}