.boosted {
	.owl-item {
		transition: $transition-base;
		border-radius: $border-radius-sm;

		&:hover {
			background: $grey-10;
		}
	}

	a:hover {
		text-decoration: none;
	}

	.item {
		display: block; // overrides flex which causes issues on IE
		padding: 10px;
	}

	[class*="owl"] {
		height: 100%;
	}
}