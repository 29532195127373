/**
 * Styles for offers/vouchers - used on offer/voucher pages, store pages, and some vertical pages
 */

//
// Single offer
//

// Offer type tag - inline (if no retailer logo)
.offer__tag {
	top: 0;
	left: 0;
	z-index: 2;
	width: auto;
	color: white;
	font-size: .625rem;
	padding: .125rem .5rem;
	background: $blue-75;
	position: absolute;
	box-shadow: $box-shadow;
	text-transform: uppercase;
	border-bottom-right-radius: .5rem;

	&.offer-type-free-donation {
		background: $green-75;
	}

	&.offer-type-offer {
		background: $pink-75;
	}

	&.offer-type-voucher {
		background: $marine-75;
	}
}

.offer__store {
	--paragraph-opacity: 0.7;
}

.offer__title {
	--paragraph-opacity: 1;
}

// Expiry / Terms / Info
.offer__extra {
	--paragraph-opacity: 0.7;
	font-size: .75rem;

	a {
		display: inline-block;
	}
}

// Terms Content (it's a toggle block)
.offer__terms {
	width: 100%;
	padding: .5rem;
	margin-top: .5rem;
	background: $white; // White bg for blue stripes
	border: 1px $blue dashed;

	// A little hack to make very long links cover multiple lines (the javascript adds <span> tags around backslashes)
	a > span {
		display: inline-block;
		text-decoration: underline;
	}
}

.offer:nth-of-type(odd) .offer__terms {
	background: $blue-10; // Blue bg for white stripes
}

//
// Featured offers
//

.featured-offer {
	text-align: left;

	// The header - we use the :before trick to make the <img> banner a background-image so we can clip it;
	.offer__header {
		width: 100%;
		overflow: hidden;
		position: relative;
		border-top-left-radius: .5rem;
		border-top-right-radius: .5rem;
		background: linear-gradient(to right, $brand-75 0%, $brand 100%);

		&:has(.offer__banner) {
			background: $grey-5;
		}

		&:before {
			content: "";
			display: block;
			padding-top: 26%;
		}

		&.offer__header--sq {
			&:before {
				padding-top: 100%;
			}
		}
	}

	// Banner image
	.offer__banner {
		top: 50%;
		width: 100%;
		min-height: 100%;
		object-fit: cover;
		position: absolute;
		transform: translateY(-50%);
	}

	// Logo
	.offer__logo {
		position: absolute;
		bottom: 10%;
		bottom: clamp(.125rem, 10%, 1.5rem);
		left: 1.5rem;
		width: auto;
		height: auto;
		max-height: 80%;
	}

	// Button
	.offer__header .offer__button {
		width: 45%;
		right: 1.5rem;
		position: absolute;
	}

	// Button + revealed voucher alignment
	.offer__header .offer__button:not(.btn-default){
		bottom: 10%;  
	}
	.offer__header .offer__button.btn-default{
		top: 10%;  
	}

	// Donation rate
	.offer__rate {
		text-align: left;
		clear: left;
	}
}

// Little override to make featured offers look better on white backgrounds
.band[class="band"] .featured-offer {
	background-color: $grey-5;
	border: 1px solid $grey-20;
}

// Black Friday background - live all-year-round
.bg-blackfriday {
	background: $black;
	background: url("/images/common/christmas/2019/bg-blackfriday-xs.jpg") repeat-y;
	background-size: 100% auto;

	@include media-breakpoint-up(sm) {
		background-image: url("/images/common/christmas/2019/bg-blackfriday-sm.jpg");
	}
	@include media-breakpoint-up(md) {
		background-image: url("/images/common/christmas/2019/bg-blackfriday-md.jpg");	
	}
	@include media-breakpoint-up(lg) {
		background-image: url("/images/common/christmas/2019/bg-blackfriday-lg.jpg");
	}
	@include media-breakpoint-up(xl) {
		background-image: url("/images/common/christmas/2019/bg-blackfriday-xl.jpg");
	}

	.band {
		position: relative;
	}

	.bg-grey-10 {
		background: transparent !important;
	}
}

// Double Donations Week
.bg-ddw {
	background: 
		url("/images/offers/double-donations-confetti.png") 50% 70% / cover no-repeat,
		linear-gradient(0deg, #54b1e4 0%, #2b5aa2 100%);
	background-size: cover;

	h1 {
		text-shadow: 0px 0px 0.5rem #2b5aa2, 
					 0px 0px 1rem #2b5aa2, 
					 0px 0px 2rem #2b5aa2;
	}

	.text-yellow {
		color: #ffd400
	}
}