// Refer

.input-semi-bg {
	input {
		border: 0;
		color: #fff;
		background-color: rgba(#fff, 0.2);
	}

	button {
		border: 0;
		color: #fff;
		background-color: rgba($input-group-addon-bg, 0.4);
	}
}