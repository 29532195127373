// store.scss

.donation-rate-table__footer td {
	background-color: white;
	border-top: 1px dotted $grey-20;
}

// Post-shop modal > voucher code
.js-voucher-code-target span {
	font-size: 1.5rem;
	padding: 5px 10px;
	font-weight: bold;
	margin: -0 auto 15px;
	display: inline-block;
	border: 2px dashed $blue;
}

.floating-store-logos {
	position: relative;
}

.floating-store-logo {
	top: var(--floating-position-y, 0);
	left: var(--floating-position-x, 0);

	position: absolute;
	max-width: 12%;
	animation: floating-logo 4s ease-in-out infinite;
	animation-delay: var(--floating-delay, '0s');
}

@keyframes floating-logo {
	0%, 100% {
		transform: translateY(0px);
	}
	50% {
		transform: translateY(-10%);
	}
}