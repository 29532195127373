#add-to-homescreen {
	bottom: 0;
	left: .5rem;
	padding: 1rem;
	position: fixed;
	background: $grey-10;
	margin-bottom: .5rem;
	width: calc(100% - 1rem);
	z-index: $zindex-popover;
	box-shadow: $box-shadow;
	border-radius: $border-radius;
}