// Bootstrap

@import "~BootstrapSass/progress";

// Stop weird gap between sections
.box.wizard-step {
	margin: 0;
}

//
// Progress section
//

#wizard-progress-bar-container {
	text-align: center;
	padding: 0 1rem 1rem;
}

.profile-progress {
	margin-bottom: .5rem;
}

.step-link {
	display: block;
	padding: .5rem;
	margin: 0 -.5rem;

	&:hover {
		text-decoration: none;
	}

	&.active {
		background: $silver;
		border-radius: $border-radius;
	}
}